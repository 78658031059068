.app-header-container {
  transition: all 0.4s;
  width: 100%;
  height: 64px;
  a {
    text-decoration: none;
  }
  .logo-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo-image {
      width: 60px;
      line-height: 0;
      margin-right: 24px;
      img {
        max-width: 100%;
        height: auto;
      }
    }
    .logo-name {
      h6 {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .user-organization-name {
    display: flex;
    align-items: center;
    height: 40px;
    text-align: center;
    justify-content: center;
    background-color: #4caf501a;
    border-radius: 4px;
    margin: 0;
    padding: 0 16px;

    h6 {
      font-size: 16px;
      color: #6cbd45;
      font-weight: 600;
    }
  }
}
.router-container {
  transition: all 0.4s;
  padding: 16px 24px 8px 24px;
  padding-left: 274px !important;
}
.app-sidebar {
  transition: all 0.4s;
  .MuiDrawer-paper {
    width: 250px;
    overflow-x: hidden;
    overflow-y: auto;
    transition: all 0.4s;
    background-color: inherit;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    z-index: 100;
    padding: 65px 0px 0 0;
  }
  .app-logo-container {
    width: 100%;
    max-width: 160px;

    .app-logo-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px 16px;
      margin-bottom: 8px;
    }
  }
  .app-master-menu {
    position: relative;
    padding: 16px 0;
    .app-master-menu-item {
      position: relative;
      padding: 0;
      display: flex;
      height: 45px;
      .MuiListItemIcon-root {
        position: relative;
        display: flex;
        width: 50px;
        height: 100%;
        align-items: center;
        justify-content: center;
        min-width: auto;
        .main-menu-icon {
          transition: all 0.4s;
          line-height: 1;
          font-size: 1.25rem;
        }
      }
      .MuiListItemText-root {
        margin: 0;
        h5 {
          font-size: inherit;
          font-weight: 500;
          color: inherit;
          white-space: nowrap;
          margin: 0;
          font-size: 13px;
        }
      }
      .beta-module-label {
        position: relative;
        font-size: 12px;
        width: 50px;
        height: auto;
        padding: 2px 0;
        font-weight: 600;
        margin-left: 8px;
      }
    }
    .app-sub-menu {
      position: relative;

      a {
        padding: 0;
        background-color: inherit;
        font-size: 12px;

        margin: 4px 0;
        .sub-menu-title {
          position: relative;
          background-color: inherit;
          font-size: 12px;
          padding: 0 0 0 56px;
          transition: all 0.4s;
          white-space: nowrap;
          margin: 0;
        }
        .beta-module-label {
          position: relative;
          font-size: 12px;
          width: 50px;
          height: auto;
          padding: 0;
          font-weight: 600;
          margin-left: 8px;
        }
      }
    }
  }
}

.select-all-button {
  text-align: right;
  padding: 0 6px;
}
.organization-selector-container {
  position: relative;
  width: 300px;
  .render-tags-wrapper {
    max-width: calc(100% - 25px);
    overflow: hidden;
    .organization-selected-tag {
      max-width: 165px;
    }
    .selected-offset-label {
      font-weight: 500;
      font-size: 15px;
      margin-left: 4px;
    }
  }
  .MuiOutlinedInput-root {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow: hidden;
    #hvOrganizationSelector {
      min-width: 25px;
    }
  }
}

.highlight-organization-selector {
  animation: glow 1s ease-in-out infinite;
}
.organization-group-wrapper {
  position: relative;
  .organization-group-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 8px;
    .title {
      .parent-name {
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
        width: 150px;

        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .select-all-children {
      position: relative;
    }
  }

  .organizations-options {
    .organization-box {
      cursor: pointer;
      display: flex;
      outline: 0;
      box-sizing: border-box;
      min-height: auto;
      align-items: center;
      padding-top: 6px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 6px;
      justify-content: flex-start;
    }
    .MuiAutocomplete-option {
      font-size: 14px;
    }
    .option-brand-icon {
      color: #6cbd45;
      font-size: 14px;
    }
  }
}
.brand-popper-wrapper {
  position: relative;
  padding: 0;

  .parent-title {
    font-size: 14px;
  }
  .brand-child-wrapper {
    position: relative;
    li {
      padding: 0;
      .brand-child-option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
        padding: 6px 16px;
        .brand-label {
          width: 220px;
          display: block;
          padding-left: 24px;
          font-size: 14px;
        }
        .brand-checkbox {
          display: block;
        }
      }
    }
  }
}

.table-settings-container {
  .header {
    padding: 8px 16px;
    h6 {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .content {
    max-width: 350px;
    padding: 8px;
  }
}

.user-popover-container {
  width: 300px;
  .user-profile-header {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    .image {
      position: relative;
    }
    .user-basic-info {
      position: relative;
      .user-name {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 4px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .user-email {
        font-size: 10px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .user-organization {
        font-size: 12px;
        font-weight: 600;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .user-control-panel {
    padding: 0 16px;
    .app-settings-item {
      margin: 8px 0;
      .title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 4px;
      }
      .contents {
        padding-left: 16px;
      }
    }
    .hv-themes-wrapper {
      position: relative;
      .theme-title {
        font-size: 13px;
        width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .theme-preview-box {
        position: relative;
        .color-swatch-wrapper {
          border-radius: 2px;
          overflow: hidden;
          display: -webkit-flex;
          display: flex;
          width: 120px;
          .color-swatch {
            width: 20px;
            min-height: 16px;
            position: relative;
            display: flex;
            /* background: rgb(250, 154, 40); */
          }
        }
      }
      .theme-default-theme {
        .primary-color {
          background-color: #00bcd4;
        }
        .secondary-color {
          background-color: #fe6f5e;
        }
        .bg-color {
          background-color: #222c30;
        }
        .paper-color {
          background-color: #28353a;
        }
        .text-primary-color {
          background-color: #ffffff;
        }
        .text-secondary-color {
          background-color: #a8a8a8;
        }
      }
      .theme-hacker {
        .primary-color {
          background-color: #00ff00;
        }
        .secondary-color {
          background-color: #fff000;
        }
        .bg-color {
          background-color: #1b191b;
        }
        .paper-color {
          background-color: #232223;
        }
        .text-primary-color {
          background-color: #eeeeee;
        }
        .text-secondary-color {
          background-color: #b1b1b1;
        }
      }
      .theme-celestial {
        .primary-color {
          background-color: #ef7c2a;
        }
        .secondary-color {
          background-color: #e5cd52;
        }
        .bg-color {
          background-color: #293030;
        }
        .paper-color {
          background-color: #1e2324;
        }
        .text-primary-color {
          background-color: #eeeeee;
        }
        .text-secondary-color {
          background-color: #b1b1b1;
        }
      }
      .theme-curiosity {
        .primary-color {
          background-color: #32ae85;
        }
        .secondary-color {
          background-color: #ff5874;
        }
        .bg-color {
          background-color: #242938;
        }
        .paper-color {
          background-color: #1d212f;
        }
        .text-primary-color {
          background-color: #eeeeee;
        }
        .text-secondary-color {
          background-color: #b1b1b1;
        }
      }
      .theme-espresso {
        .primary-color {
          background-color: #a04f36;
        }
        .secondary-color {
          background-color: #86c166;
        }
        .bg-color {
          background-color: #1f1614;
        }
        .paper-color {
          background-color: #1b0000;
        }
        .text-primary-color {
          background-color: #eeeeee;
        }
        .text-secondary-color {
          background-color: #b1b1b1;
        }
      }
      .theme-legacy {
        .primary-color {
          background-color: #ffffff;
        }
        .secondary-color {
          background-color: #878787;
        }
        .bg-color {
          background-color: #121212;
        }
        .paper-color {
          background-color: #050505;
        }
        .text-primary-color {
          background-color: #ffffff;
        }
        .text-secondary-color {
          background-color: #878787;
        }
      }
      .theme-sweetleaf {
        .primary-color {
          background-color: #314e88;
        }
        .secondary-color {
          background-color: #d6381b;
        }
        .bg-color {
          background-color: #f4f2f3;
        }
        .paper-color {
          background-color: #ffffff;
        }
        .text-primary-color {
          background-color: #222222;
        }
        .text-secondary-color {
          background-color: #707070;
        }
      }
      .theme-winter-cold {
        .primary-color {
          background-color: #4d869c;
        }
        .secondary-color {
          background-color: #7ab2b2;
        }
        .bg-color {
          background-color: #cde8e5;
        }
        .paper-color {
          background-color: #eef7ff;
        }
        .text-primary-color {
          background-color: #222222;
        }
        .text-secondary-color {
          background-color: #707070;
        }
      }
      .theme-orange {
        .primary-color {
          background-color: #eb6440;
        }
        .secondary-color {
          background-color: #497174;
        }
        .bg-color {
          background-color: #d6e4e5;
        }
        .paper-color {
          background-color: #eff5f5;
        }
        .text-primary-color {
          background-color: #222222;
        }
        .text-secondary-color {
          background-color: #707070;
        }
      }
      .theme-ocean-flame {
        .primary-color {
          background-color: #2c3e50;
        }
        .secondary-color {
          background-color: #e74c3c;
        }
        .bg-color {
          background-color: #ecf0f1;
        }
        .paper-color {
          background-color: #ffffff;
        }
        .text-primary-color {
          background-color: #2c3e50;
        }
        .text-secondary-color {
          background-color: #95a5a6;
        }
      }
      .theme-midnight-ember {
        .primary-color {
          background-color: #4b77be;
        }
        .secondary-color {
          background-color: #c0392b;
        }
        .bg-color {
          background-color: #e8e8e8;
        }
        .paper-color {
          background-color: #ffffff;
        }
        .text-primary-color {
          background-color: #333333;
        }
        .text-secondary-color {
          background-color: #7f8c8d;
        }
      }
      .theme-aurora {
        .primary-color {
          background-color: #46b3e6; /* Light Blue */
        }
        .secondary-color {
          background-color: #f9a825; /* Yellow */
        }
        .bg-color {
          background-color: #2a3b4c; /* Dark Blue-Grey */
        }
        .paper-color {
          background-color: #243342; /* Darker Blue-Grey */
        }
        .text-primary-color {
          background-color: #f1f1f1; /* Light Grey */
        }
        .text-secondary-color {
          background-color: #9fa8a3; /* Muted Grey */
        }
      }
      .theme-desert-mirage {
        .primary-color {
          background-color: #d4a373; /* Sandy Brown */
        }
        .secondary-color {
          background-color: #6a994e; /* Olive Green */
        }
        .bg-color {
          background-color: #3a2d2a; /* Dark Brown */
        }
        .paper-color {
          background-color: #2d1f1b; /* Darker Brown */
        }
        .text-primary-color {
          background-color: #f5f5f5; /* Light Grey */
        }
        .text-secondary-color {
          background-color: #b0a999; /* Muted Brown-Grey */
        }
      }
      .theme-spring-meadow {
        .primary-color {
          background-color: #7bc043; /* Fresh Green */
        }
        .secondary-color {
          background-color: #ff6f61; /* Coral Pink */
        }
        .bg-color {
          background-color: #f7f9fb; /* Very Light Grey */
        }
        .paper-color {
          background-color: #ffffff; /* White */
        }
        .text-primary-color {
          background-color: #333333; /* Dark Grey */
        }
        .text-secondary-color {
          background-color: #757575; /* Medium Grey */
        }
      }
      .theme-sunset-glow {
        .primary-color {
          background-color: #f39c12; /* Bright Orange */
        }
        .secondary-color {
          background-color: #e74c3c; /* Bright Red */
        }
        .bg-color {
          background-color: #fcf3cf; /* Very Light Yellow */
        }
        .paper-color {
          background-color: #ffffff; /* White */
        }
        .text-primary-color {
          background-color: #2c3e50; /* Dark Blue */
        }
        .text-secondary-color {
          background-color: #7f8c8d; /* Medium Grey */
        }
      }
    }
  }
}
