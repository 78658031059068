.role_container {
  border: 1px solid #525663;
}
.counter-container {
  background-color: rgba(76, 175, 80, 0.1);
  border-radius: 5px;
}

.counter-number {
  color: #6cbd45;
  font-weight: 600;
}

.module-container {
  padding: 10px 5px;
  border: 1px solid #525663;
  background-color: rgba(60, 68, 82, 0.2);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 95%;
  cursor: pointer;
  user-select: none;
  margin-bottom: 8px;
}

.scrolling-wrapper {
  direction: rtl;
}

.modules-wrapper {
  max-height: 340px;
  overflow: auto;
}

/* Track */
.scrolling-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

/* Handle */
.scrolling-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

/* Handle on hover */
.scrolling-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrolling-wrapper::-webkit-scrollbar {
  width: 5px;
}

.permission-container {
  border: 1px solid #525663;
  border-radius: 5px;
}

.attachment-controller {
  position: relative;
  .file-selector {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.dashed-container {
  border: 1px dashed #ffffff4d;
  border-radius: 5px;
  position: relative;
}

.dashed-label {
  position: absolute;
  top: -13px;
  left: 11px;
  font-size: 13px;
}

.card-hover:hover {
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
}

.attachment-details::after {
  content: "";
  width: 250px;
  height: 30px;
  position: absolute;
  left: 0;
  bottom: 90px;
  background: linear-gradient(0deg, "#000000" 0%, transparent 100%);
  z-index: 1;
}

.attachment-container:hover {
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
}
