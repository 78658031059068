.bg-cover-image {
  /* The image used */
  background-image: url("../assets/backgrounds/login2.jpg");

  /* Full height */
  height: 100vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.social-media-icon:hover {
  transform: scale(1.1);
  transition: 0.2s ease-in-out;
}

.social-media-icon {
  cursor: pointer;
}
