.dashboard-cards {
  height: 100%;
  .header {
    padding: 16px;
    .main-heading {
      position: relative;
      font-size: 16px;
      font-weight: 600;
    }
    .sub-heading {
      position: relative;
      font-size: 12px;
    }
  }
  .contents {
    position: relative;
    padding: 8px 0;
    .table-container {
      max-height: 500px;
      overflow: auto;
    }
    .dashboard-table {
      table {
        thead {
          tr {
            th {
              white-space: nowrap;
            }
          }
        }
        tbody {
          tr {
            td {
              white-space: nowrap;
              font-size: 13px;
              height: 45px;
              span {
                width: 220px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
}
.empty-chart-wrapper {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .empty-chart-animation {
    position: relative;
  }
  .empty-chart-text {
    p {
      font-size: 12px;
      font-weight: 500;
    }
  }
}
.no-rows-container {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  .icon {
    font-size: 60px;
  }
  .content {
    h4 {
      font-weight: 600;
    }
  }
}
.module-table-container {
  position: relative;
  margin: 16px 0;
  width: 100%;
  .table-search-filter-wrapper {
    padding: 8px 16px;
  }
  .table-actions-wrapper {
    padding: 8px 16px;
  }
  .master-table-wrapper {
    position: relative;
    .MuiTableContainer-root {
      overflow: auto;
      table {
        tbody {
          tr {
            td {
              max-width: 250px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              height: 50px;
            }
          }
        }
      }
    }
    .table-row-spacer {
      height: 8px;
    }
    .table-row-no-border {
      th {
        border-bottom: 0;
      }
      td {
        border-bottom: 0;
      }
    }
    .table-cell-header {
      font-weight: 600;
      white-space: nowrap;
    }
    .data-cell {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 90px;
    }
    .view-more-btn-cell {
      margin-right: 16px;
      text-transform: capitalize;
    }
  }

  .view-all-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    .view-all-btn {
      margin-top: 32px;
    }
  }
}
.date-range-container {
  padding: 8px 0;
  width: 550px;

  .header {
    position: relative;
    padding: 0 8px;
  }
  .content {
    position: relative;

    .tab-panel {
      padding: 0px;
    }
    .quick-filters {
      position: relative;
    }
    .absolute-filters {
      padding: 16px;
      position: relative;
    }
  }
  .footer {
    padding: 8px;
  }
}
.app-module-container {
  position: relative;
  .app-modeule-paper {
    height: "100%";
    margin: 0;
    padding: 20;
    border-radius: 6;
    display: "relative";
    overflow: "hidden";
  }
  .app-module-header {
    margin-bottom: 32px;
    .module-title {
      font-size: 1.7rem;
      font-weight: 600;
      margin-bottom: 4px;
    }
    .module-description {
      font-size: 14px;
    }
  }
  .app-module-actions {
    margin-bottom: 24px;
  }
  .app-module-contents {
    position: relative;
  }
  .card-title {
    font-weight: 400;
    font-size: 0.775rem;
    line-height: 1.73;
  }
  .card-link {
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.5;
  }
  .card-description {
    font-weight: 400;
    font-size: 0.775rem;
    line-height: 1.4;
  }
}
.app-master-drawer-wrapper {
  width: 720px;
  overflow-x: hidden;
  overflow-y: auto;

  .header {
    padding: 16px;
    margin: 0;
    .main-heading {
      position: relative;
      font-weight: 600;
      font-size: 1.3rem;
    }
    .sub-heading {
      position: relative;
      font-size: 14px;
    }
  }
  .contents {
    padding: 8px 16px;
    position: relative;
  }
}
.include-chip {
  background-color: #4caf50 !important;
  .MuiChip-label {
    font-weight: 500;
    font-size: 12px;
  }
  .MuiAvatar-root {
    background-color: #2a842d !important;
  }
}
.exclude-chip {
  background-color: #f44336 !important;
  .MuiChip-label {
    font-weight: 500;
    font-size: 12px;
  }
  .MuiAvatar-root {
    background-color: #c31d11 !important;
  }
}
.app-master-list-wrapper {
  position: relative;
  table {
    tbody {
      tr {
        min-height: 50px;
      }
    }
  }
  .list-cell-header {
    position: relative;
    width: 220px;
  }
  .value-cell {
    word-break: break-word;
  }
}
.app-section-box {
  position: relative;
  margin: 16px 0;
  .app-section-box-header {
    margin-bottom: 8px;
    padding: 0 16px;
    .section-title {
      font-size: 14px;
      font-weight: 600;
    }
  }
}
.app-master-document-view {
  position: relative;
  .doc-view-table {
    tbody {
      tr {
        td:first-child {
          width: 250px;
        }
      }
    }
  }
  .item-label {
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
  }
  .item-value {
    display: block;
    font-size: 12px;
    padding-right: 16px;
    word-break: break-all;
  }
  tr:last-child {
    td {
      border: none;
    }
  }
}
.module-details-container {
  position: relative;
  max-width: 1000px;
  margin: auto;
  .module-details-wrapper {
    position: relative;
    .time-span-label {
      font-size: 12px;
      font-weight: 500;
    }
  }
}
.step-label-wrapper {
  margin-left: 8px;
  .step-title {
    font-size: 18px;
    margin: 0;
  }
  .step-info {
    font-size: 12px;
    font-weight: 400;
  }
}
.step-content-wrapper {
  margin-left: 8px;
  .step-contents {
    margin: 8px 0;
  }
}
.issues-filter-block {
  margin: 16px 0 24px 0;
  .issues-filter-block-title {
    font-size: 16px;
    margin-bottom: 16px;
  }
}
.app-markdown-editor {
  position: relative;
  .mde-header,
  .mde-preview {
    * {
      color: inherit !important;
      border-color: inherit !important;
      word-break: break-word;
    }
  }
}
